import PreflightApi from "../generated/api/PreflightApi";

class PreflightService {
    constructor() {
        this.PreflightApi = new PreflightApi();
    }

    async GetPreflightList(params) {
        return await window.$A.HandleApiResponse(
            this.PreflightApi.getPreflights(params)
        );
    }

    async GetPreflight(preflightId) {
        return await window.$A.HandleApiResponse(
            this.PreflightApi.getPreflight(preflightId)
        )
    }

    async GetPreflightUploadUrl(uploadRequest) {
        return await window.$A.HandleApiResponse(
            this.PreflightApi.getUploadUrl(uploadRequest)
        );
    }

    async GetPreflightColorProfiles() {
        return await window.$A.HandleApiResponse(
            this.PreflightApi.getColorProfiles()
        );
    }

    async RequeuePreflight(preflightId) {
        return await window.$A.HandleApiResponse(
            this.PreflightApi.requeuePreflight(preflightId)
        )
    }

    async GetPreflightDownloadUrl(preflightId) {
        return await window.$A.HandleApiResponse(
            this.PreflightApi.getDownloadUrl(preflightId)
        )
    }

    async PreflightCompleteUpload(preflightId) {
        return await window.$A.HandleApiResponse(
            this.PreflightApi.completeUpload(preflightId)
        )
    }

    async DeletePreflight(preflightId) {
        return await window.$A.HandleApiResponse(
            this.PreflightApi.deletePreflight(preflightId)
        );
    }
}

export default PreflightService;
