/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApiError from '../model/ApiError';
import ColorProfileResponse from '../model/ColorProfileResponse';
import PreSignedUrlResponse from '../model/PreSignedUrlResponse';
import Preflight from '../model/Preflight';
import PreflightCallbackRequest from '../model/PreflightCallbackRequest';
import PreflightPaginatedResult from '../model/PreflightPaginatedResult';
import PreflightUploadRequest from '../model/PreflightUploadRequest';
import ProblemDetails from '../model/ProblemDetails';
import SuccessResponse from '../model/SuccessResponse';

/**
* Preflight service.
* @module api/PreflightApi
* @version V1
*/
export default class PreflightApi {

    /**
    * Constructs a new PreflightApi. 
    * @alias module:api/PreflightApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the completeUpload operation.
     * @callback module:api/PreflightApi~completeUploadCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Preflight} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} preflightId 
     * @param {module:api/PreflightApi~completeUploadCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Preflight}
     */
    completeUpload(preflightId, callback) {
      let postBody = null;
      // verify the required parameter 'preflightId' is set
      if (preflightId === undefined || preflightId === null) {
        throw new Error("Missing the required parameter 'preflightId' when calling completeUpload");
      }

      let pathParams = {
        'preflightId': preflightId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Preflight;
      return this.apiClient.callApi(
        '/preflights/{preflightId}/complete-upload', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getColorProfiles operation.
     * @callback module:api/PreflightApi~getColorProfilesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ColorProfileResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/PreflightApi~getColorProfilesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ColorProfileResponse}
     */
    getColorProfiles(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ColorProfileResponse;
      return this.apiClient.callApi(
        '/preflights/color-profiles', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getDownloadUrl operation.
     * @callback module:api/PreflightApi~getDownloadUrlCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} preflightId 
     * @param {module:api/PreflightApi~getDownloadUrlCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    getDownloadUrl(preflightId, callback) {
      let postBody = null;
      // verify the required parameter 'preflightId' is set
      if (preflightId === undefined || preflightId === null) {
        throw new Error("Missing the required parameter 'preflightId' when calling getDownloadUrl");
      }

      let pathParams = {
        'preflightId': preflightId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/preflights/{preflightId}/download', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPreflight operation.
     * @callback module:api/PreflightApi~getPreflightCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Preflight} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} preflightId 
     * @param {module:api/PreflightApi~getPreflightCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Preflight}
     */
    getPreflight(preflightId, callback) {
      let postBody = null;
      // verify the required parameter 'preflightId' is set
      if (preflightId === undefined || preflightId === null) {
        throw new Error("Missing the required parameter 'preflightId' when calling getPreflight");
      }

      let pathParams = {
        'preflightId': preflightId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Preflight;
      return this.apiClient.callApi(
        '/preflights/{preflightId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPreflights operation.
     * @callback module:api/PreflightApi~getPreflightsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PreflightPaginatedResult} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} [page = 1)] 
     * @param {Number} [pageSize = 50)] 
     * @param {module:api/PreflightApi~getPreflightsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PreflightPaginatedResult}
     */
    getPreflights(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'pageSize': opts['pageSize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PreflightPaginatedResult;
      return this.apiClient.callApi(
        '/preflights', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getUploadUrl operation.
     * @callback module:api/PreflightApi~getUploadUrlCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PreSignedUrlResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/PreflightUploadRequest} [preflightUploadRequest] 
     * @param {module:api/PreflightApi~getUploadUrlCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PreSignedUrlResponse}
     */
    getUploadUrl(opts, callback) {
      opts = opts || {};
      let postBody = opts['preflightUploadRequest'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PreSignedUrlResponse;
      return this.apiClient.callApi(
        '/preflights/get-upload-url', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the handleCallback operation.
     * @callback module:api/PreflightApi~handleCallbackCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} preflightId 
     * @param {String} callbackToken 
     * @param {Object} opts Optional parameters
     * @param {module:model/PreflightCallbackRequest} [preflightCallbackRequest] 
     * @param {module:api/PreflightApi~handleCallbackCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    handleCallback(preflightId, callbackToken, opts, callback) {
      opts = opts || {};
      let postBody = opts['preflightCallbackRequest'];
      // verify the required parameter 'preflightId' is set
      if (preflightId === undefined || preflightId === null) {
        throw new Error("Missing the required parameter 'preflightId' when calling handleCallback");
      }
      // verify the required parameter 'callbackToken' is set
      if (callbackToken === undefined || callbackToken === null) {
        throw new Error("Missing the required parameter 'callbackToken' when calling handleCallback");
      }

      let pathParams = {
        'preflightId': preflightId,
        'callbackToken': callbackToken
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/preflights/{preflightId}/callback/{callbackToken}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the requeuePreflight operation.
     * @callback module:api/PreflightApi~requeuePreflightCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} preflightId 
     * @param {module:api/PreflightApi~requeuePreflightCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    requeuePreflight(preflightId, callback) {
      let postBody = null;
      // verify the required parameter 'preflightId' is set
      if (preflightId === undefined || preflightId === null) {
        throw new Error("Missing the required parameter 'preflightId' when calling requeuePreflight");
      }

      let pathParams = {
        'preflightId': preflightId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/preflights/{preflightId}/requeue', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deletePreflight operation.
     * @callback module:api/PreflightApi~deletePreflightCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SuccessResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} preflightId 
     * @param {module:api/PreflightApi~deletePreflightCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SuccessResponse}
     */
    deletePreflight(preflightId, callback) {
      let postBody = null;
      // verify the required parameter 'preflightId' is set
      if (preflightId === undefined || preflightId === null) {
        throw new Error("Missing the required parameter 'preflightId' when calling deletePreflight");
      }

      let pathParams = {
        'preflightId': preflightId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AccessToken'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/preflights/{preflightId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

}
