export const PROOF_ACCESS_PERMISSIONS = {
  OWNED_ONLY: 1,
  TEAMS_ONLY: 2,
  EVERYTHING: 3,
  NONE: 4,
};

export const DOC_TYPES = {
  VIDEO: "video",
  HTML: "html",
  AUDIO: "audio",
  IMAGE: "image",
  DOCUMENT: "document",
  WEB_URL: "web_url"
}

export const ANNOTATION_TYPES = {
  DRAW: 'draw',
  ARROW: 'arrow',
  RECT: 'rect',
  POINT: 'point'
}

export const PROOF_VIEW_MODES = {
  APPROVER_MODE: 0,
  PROOF_OWNER_MODE: 1,
  COMMENTER_MODE: 2,
}

export const TYPES_ENUM = {
  STRING: 'string',
  FUNCTION: 'function'
}

export const DEFAULT_ACCOUNT_NAME = "Ashore";
export const DEFAULT_ACCOUNT_LOGO = "img/logo-large.svg"

export const DEFAULT_PADDING = {
   DEFAULT_X_PADDING: 15,
   DEFAULT_Y_PADDING: 10
}

export const CURSOR_TYPE = {
  DEFAULT: 'default',
  MOVE: 'move',
  POINTER: 'pointer'
}

export const SSO_PROVIDERS = {
  MICROSOFT: "microsoft",
  GOOGLE: "google"
}

export const NOTIFICATION_FREQUENCY_OPTIONS = {
  NEVER: 0,
  WHEN_USER_SESSION_ENDS: 1,
  EVERY_10_MINUTES: 2,
  EVERY_30_MINUTES: 3,
  EVERY_HOUR: 4,
  DAILY: 5
}

export const COLOR_PROFILE_OPTIONS = [
  { text: "sRGB 2014", value: "RGB/sRGB2014.icc" },
  { text: "Video HD", value: "RGB/VideoHD.icc" },
  { text: "sRGB v4 ICC Preference", value: "RGB/sRGB_v4_ICC_preference.icc" },
  { text: "SMPTE-C", value: "RGB/SMPTE-C.icc" },
  { text: "PAL/SECAM", value: "RGB/PAL_SECAM.icc" },
  { text: "ColorMatch RGB", value: "RGB/ColorMatchRGB.icc" },
  { text: "Apple RGB", value: "RGB/AppleRGB.icc" },
  { text: "Video PAL", value: "RGB/VideoPAL.icc" },
  { text: "Video NTSC", value: "RGB/VideoNTSC.icc" },
  { text: "Adobe RGB 1998", value: "RGB/AdobeRGB1998.icc" },
  { text: "Coated Fogra39 (260%)", value: "CMYK/Coated_Fogra39L_VIGC_260.icc" },
  { text: "Coated Fogra39 (300%)", value: "CMYK/Coated_Fogra39L_VIGC_300.icc" },
  { text: "GRACoL 2013 (Uncoated CRPC3)", value: "CMYK/GRACoL2013UNC_CRPC3.icc" },
  { text: "GRACoL 2006 (Coated 1v2)", value: "CMYK/GRACoL2006_Coated1v2.icc" },
  { text: "GRACoL 2013 (CRPC6)", value: "CMYK/GRACoL2013_CRPC6.icc" },
  { text: "SNAP 2007", value: "CMYK/SNAP2007.icc" },
  { text: "CGATS21 (CRPC6)", value: "CMYK/CGATS21_CRPC6.icc" },
  { text: "CGATS21 (CRPC7)", value: "CMYK/CGATS21_CRPC7.icc" },
  { text: "SWOP 2006 (Coated 3v2)", value: "CMYK/SWOP2006_Coated3v2.icc" },
  { text: "CGATS21 (CRPC5)", value: "CMYK/CGATS21_CRPC5.icc" },
  { text: "CGATS21 (CRPC4)", value: "CMYK/CGATS21_CRPC4.icc" },
  { text: "SC Paper (ECI)", value: "CMYK/SC_paper_eci.icc" },
  { text: "SWOP 2006 (Coated 5v2)", value: "CMYK/SWOP2006_Coated5v2.icc" },
  { text: "CGATS21 (CRPC1)", value: "CMYK/CGATS21_CRPC1.icc" },
  { text: "SWOP 2013C3 (CRPC5)", value: "CMYK/SWOP2013C3_CRPC5.icc" },
  { text: "CGATS21 (CRPC3)", value: "CMYK/CGATS21_CRPC3.icc" },
  { text: "CGATS21 (CRPC2)", value: "CMYK/CGATS21_CRPC2.icc" },
  { text: "Uncoated Fogra47 (260%)", value: "CMYK/Uncoated_Fogra47L_VIGC_260.icc" },
  { text: "Uncoated Fogra47 (300%)", value: "CMYK/Uncoated_Fogra47L_VIGC_300.icc" },
]
