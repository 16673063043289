<template>
  <div class="nav-margin">
    <navigation></navigation>
    <nonPremiumPreflightScreen v-if="!isPremiumAccount" />
    <div v-else class="preflight-page">
      <preflightSidebar ref="preflightSidebar" :unsavedFiles="unsavedFiles" @renderNewPreflight="renderNewPreflight"
        @updatePreflightSettings="updatePreflightSettings" @showUnsavedFileInfo="showUnsavedFileInfo" />
      <preflightUploadArea ref="fileUploadSection" @addNewFile="addNewFile" @unsavedFiles="handleUnsavedFiles"
        @updateUnsavedFiles="updateUnsavedFiles" />
    </div>
  </div>
</template>

<script>
import preflightSidebar from "../components/preflight-archive/preflightSidebar.vue";
import preflightUploadArea from "../components/preflight-archive/preflightUploadArea.vue";
import nonPremiumPreflightScreen from "../components/preflight-archive/nonPremiumPreflightScreen.vue";

export default {
  components: {
    preflightSidebar,
    preflightUploadArea,
    nonPremiumPreflightScreen
  },
  data() {
    return {
      isPremiumAccount: true,
      currentUnsavedFileId: null,
      unsavedFiles: [],
    }
  },
  methods: {
    renderNewPreflight() {
      this.$refs.fileUploadSection.removeFile();
    },
    async updatePreflightSettings(file) {
      this.currentUnsavedFileId = null;
      await this.$refs.fileUploadSection.removeFile();
      try {
        const preflightData = await this.$A.PreflightService.GetPreflight(file.id);
        if (preflightData) {
          await this.$refs.fileUploadSection.updateFile(preflightData);
        } else {
          console.error('Failed to fetch preflight data');
          window.$A.AlertUser('Error updating preflight', 'error');
        }
      } catch (error) {
        console.error('Error updating preflight settings:', error);
        window.$A.AlertUser('Error updating preflight settings', 'error');
      }
    },
    showUnsavedFileInfo(unsavedFile) {
      this.currentUnsavedFileId = unsavedFile.fileId;
      this.$refs.fileUploadSection.removeFile();
      this.$refs.fileUploadSection.extractPdfDimensions(unsavedFile.file, true);
    },
    addNewFile() {
      if (this.currentUnsavedFileId) {
        this.unsavedFiles = this.unsavedFiles.filter(file => file.fileId !== this.currentUnsavedFileId);
      }
      this.$refs.preflightSidebar.addNewPreflight();
    },
    handleUnsavedFiles(files) {
      const filesArray = Array.from(files);
      const updatedFiles = filesArray.map(file => ({
        file,
        fileId: this.$A.GetUUID()
      }));
      const firstFileId = updatedFiles[0].fileId;
      this.currentUnsavedFileId = firstFileId;
      this.unsavedFiles.push(...updatedFiles)
      this.$refs.preflightSidebar.selectFile(firstFileId);
    },
    updateUnsavedFiles(file) {
      if (this.currentUnsavedFileId) {
        this.unsavedFiles = this.unsavedFiles.filter(file => file.fileId !== this.currentUnsavedFileId);
        this.$refs.preflightSidebar.addFileToToday(file);
      }
    }
  },
  mounted() {
    this.isPremiumAccount = _.get(this.$A, 'Auth.account.subscriptionState.sku', []).includes('enterprise') || _.get(this.$A, 'Auth.account.subscriptionState.sku', []).includes('premium');
  }
};
</script>

<style scoped>
.preflight-page {
  display: flex;
  height: calc(100vh - 64px); /* Adjust based on your navigation height */
}
</style>
