<template>
  <div class="left-sidebar bg-white border-end border-gray-200 d-flex flex-column">
    <div class="px-4 py-3 border-bottom border-gray-200">
      <div class="d-flex align-items-center justify-content-between">
        <h6 class="fw-bold mb-0 text-gray-900">Preflights</h6>
        <button class="btn btn-link p-0" @click="addNewPreflight">
          <img src="/img/plus.svg" width="20" alt="plus-icon" />
        </button>
      </div>
    </div>

    <div class="px-4 py-3 border-bottom border-gray-200">
      <div class="position-relative">
        <img src="/img/search.svg" class="position-absolute start-0 top-50 translate-middle-y ml-3" width="20"
          alt="search-icon" />
        <input type="text" v-model="searchQuery" class="search-input pl-5" placeholder="Search..." />
      </div>
    </div>

    <div class="flex-1 overflow-auto h-100 d-flex flex-column gap-4 px-4 pb-3" @scroll="onScroll">
      <!-- New Preflight Section -->
      <div>
        <h6 class="category-text"></h6>
        <ul class="d-flex flex-column list-unstyled mb-0">
          <li class="position-relative">
            <div class="file-name cursor-pointer rounded-md py-2 text-decoration-none d-block text-nowrap text-truncate"
              :class="{ 'selected-file': selectedFileId === 'new-preflight' }"
              @click="addNewPreflight">
              New Preflight
            </div>
          </li>
        </ul>
      </div>

      <!-- Unsaved Files Section -->
      <div v-if="unsavedFiles.length">
        <h6 class="category-text">Unsaved Files</h6>
        <ul class="d-flex flex-column list-unstyled mb-0">
          <li v-for="unsavedFile in unsavedFiles" :key="unsavedFile.fileId" class="position-relative">
            <div class="file-name cursor-pointer rounded-md py-2 text-decoration-none d-block text-nowrap text-truncate"
              :class="{
                'selected-file': selectedFileId === unsavedFile.fileId,
              }" @click="selectUnsavedFile(unsavedFile)">
              {{ unsavedFile.file.name }}
            </div>
          </li>
        </ul>
      </div>

      <!-- Existing Files Sections -->
      <div v-for="(files, category) in categorizedFiles" :key="category">
        <div v-if="files.length > 0 && category !== 'New Preflight'">
          <h6 class="category-text">{{ category }}</h6>
          <ul class="d-flex flex-column list-unstyled mb-0">
            <li v-for="file in files" :key="file.id" class="position-relative" @mouseover="hoverFile(file)"
              @mouseleave="leaveFile()">
              <div
                class="file-name cursor-pointer rounded-md my-1 text-decoration-none d-block text-nowrap text-truncate"
                :class="{
                  'selected-file': selectedFileId === file.id,
                  'file-hover': isFileHovered(file),
                }" @click="showFile(file)">
                {{ file.fileName }}
              </div>
              <div v-if="file.id && isFileHovered(file) && file.id !== 'new-preflight'" class="cursor-pointer" @click="toggleDropdown(file.id)">
                <img src="/img/review/icons/dots-vertical.svg" width="20" alt="kebab-menu"
                  class="position-absolute end-0 top-50 translate-middle-y" />
              </div>
              <div v-if="dropdownOpen === file.id" class="dropdown-menu">
                <ul class="mb-0">
                  <li @click="downloadFile(file)">
                    <img src="/img/download-icon.svg" alt="Download Icon" />
                    <span class="dropdown-text">Download</span>
                  </li>
                  <li @click="requeueFile(file)">
                    <img src="/img/duplicate.svg" alt="Re-Queue Icon" />
                    <span class="dropdown-text">Re-Queue</span>
                  </li>
                  <li @click="deleteFile(file)">
                    <img src="/img/trash-dark.svg" alt="Delete Icon" />
                    <span class="dropdown-text">Delete</span>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="loading" class="d-flex justify-content-center spinner-container">
        <div class="spinner"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'preflightSidebar',
  props: {
    unsavedFiles: {
      required: true,
      type: Array,
    }
  },
  data() {
    return {
      files: [],
      selectedFileId: 'new-preflight',
      dropdownOpen: null,
      searchQuery: '',
      page: 1,
      totalPages: 1,
      loading: false,
      hoveredFileId: null,
    };
  },
  computed: {
    categorizedFiles() {
      const today = new Date().toISOString().split('T')[0];
      const yesterday = new Date(Date.now() - 864e5).toISOString().split('T')[0];
      const previous7Days = new Date(Date.now() - 7 * 864e5).toISOString().split('T')[0];
      const filteredFiles = this.files.filter((file) =>
        file.fileName.toLowerCase().includes(this.searchQuery.toLowerCase())
      );

      return {
        'New Preflight': [{ id: 'new-preflight', fileName: 'New Preflight', createdAt: today }],
        Today: filteredFiles.filter((file) => file.createdAt.startsWith(today)),
        Yesterday: filteredFiles.filter((file) => file.createdAt.startsWith(yesterday)),
        'Previous 7 Days': filteredFiles.filter(
          (file) => file.createdAt < yesterday && file.createdAt >= previous7Days
        ),
      };
    },
  },
  methods: {
    hoverFile(file) {
      this.hoveredFileId = file.id;
    },
    leaveFile() {
      this.hoveredFileId = null;
    },
    isFileHovered(file) {
      return this.hoveredFileId === file.id || this.selectedFileId === file.id;
    },
    addNewPreflight() {
      this.selectedFileId = 'new-preflight';
      this.$emit('renderNewPreflight');
    },
    addFileToToday(file) {
      const today = new Date().toISOString().split('T')[0];
      const newFile = { ...file, createdAt: today };
      this.files.unshift(newFile);
    },
    selectUnsavedFile(file) {
      this.selectFile(file.fileId)
      this.$emit('showUnsavedFileInfo', file);
    },
    selectFile(fileId) {
      this.selectedFileId = fileId;
    },
    toggleDropdown(fileId) {
      this.dropdownOpen = this.dropdownOpen === fileId ? null : fileId;
    },
    async downloadFile(file) {
      console.log('Download file', file.id);
      // this.$A.PreflightService.GetPreflightDownloadUrl(file.id)
      const downloadUrl = await this.$A.PreflightService.GetPreflightDownloadUrl(file.id);
      if(downloadUrl && downloadUrl.length > 0){
        window.open(downloadUrl, '_blank');
      }else{
        window.$A.AlertUser('No download URL found', 'error');
      }
      this.dropdownOpen = null;
    },

    async requeueFile(file) {
      this.dropdownOpen = null;
      this.selectFile(file.id);
      // Instead of requeuing immediately, get the preflight data and show the settings form
      const preflightData = await this.$A.PreflightService.GetPreflight(file.id);
      if (preflightData) {
        // Set requeue mode to true to indicate we're requeuing an existing preflight
        preflightData.isRequeue = true;
        this.$emit('updatePreflightSettings', preflightData);
      }
    },
    async deleteFile(file) {
      try {
        // Close dropdown
        this.dropdownOpen = null;
        
        // Call the API to delete the preflight
        const response = await window.$A.PreflightService.DeletePreflight(file.id);
        
        if (response && response.success) {
          // Remove from local files array
          this.files = this.files.filter((f) => f.id !== file.id);
          
          // If the deleted file was selected, clear the view
          if (this.selectedFileId === file.id) {
            this.$emit('renderNewPreflight');
          }
          
          window.$A.AlertUser('Preflight deleted successfully', 'success');
        } else {
          window.$A.AlertUser('Failed to delete preflight', 'error');
        }
      } catch (error) {
        console.error('Error deleting preflight:', error);
        window.$A.AlertUser('Error deleting preflight', 'error');
      }
    },
    closeDropdown(event) {
      const dropdownMenu = event.target.closest('.dropdown-menu');
      const dropdownToggle = event.target.closest('.position-absolute.end-0');

      if (!dropdownMenu && !dropdownToggle) {
        this.dropdownOpen = null;
      }
    },
    async fetchFiles() {
      if (this.loading) return;
      this.loading = true;

      const response = await this.$A.PreflightService.GetPreflightList({
        page: this.page,
        pageSize: 50,
      });

      if (response.items) {
        this.files.push(...response.items);
        this.totalPages = response.totalPages;
      }

      this.loading = false;
      this.page += 1;
      // Keep new preflight selected after loading files
      this.selectedFileId = 'new-preflight';
    },
    onScroll(event) {
      const bottom = event.target.scrollHeight === parseInt(event.target.scrollTop + event.target.clientHeight);
      if (bottom && this.page !== this.totalPages + 1) {
        this.fetchFiles();
      }
    },
    async showFile(file) {
      if (file.id === 'new-preflight') {
        this.selectFile('new-preflight');
        this.$emit('renderNewPreflight');
        return;
      }

      this.selectFile(file.id);
      const preflightData = await this.$A.PreflightService.GetPreflight(file.id);
      if (preflightData) {
        this.$emit('updatePreflightSettings', preflightData);
      }
    },
  },
  async mounted() {
    document.addEventListener('click', this.closeDropdown);
    await this.fetchFiles();
    // No need to call addNewPreflight here as selectedFileId is already set to 'new-preflight' by default
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeDropdown);
  },
};
</script>

<style scoped>
.left-sidebar {
  height: calc(100vh - 64px);
  width: 300px;
}

.search-input {
  width: 252px;
  background-color: #f9f9f9;
  padding: 9px 13px 9px 13px;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #b5b5b8;
  box-shadow: 0px 1px 2px 0px #080b0d0d;
}

.file-name {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1b1b1c;
  padding: 8px 12px 8px 8px;
  border-radius: 6px;
}

.selected-file {
  background-color: #f0f9f4;
}

.file-hover .dropdown-menu {
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

.category-text {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #707073;
  margin-top: 16px;
}

.dropdown-menu {
  display: block;
  position: absolute;
  border-radius: 6px;
  padding: 8px;
  box-shadow: 0px 0px 0px 1px #0000000d, 0px 4px 6px -2px #0000000d,
    0px 10px 15px -3px #0000001a;
  z-index: 1000;
  width: 100%;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dropdown-menu li img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.dropdown-menu li:hover {
  background-color: #f5f5f5;
}

.dropdown-text {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #3e3e40;
}

.spinner-container {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
