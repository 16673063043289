<template>
  <div class="progress-container">
    <div class="progress-bar" :style="barStyle"></div>
  </div>
</template>

<script>
export default {
  name: 'progressBar',
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    barColor: {
      type: String,
      default: '#019256',
    },
    backgroundColor: {
      type: String,
      default: '#F5F5F5',
    },
    interval: {
      type: Number,
      default: 100,
    },
    autoIncrease: {
      type: Boolean,
      default: true,
    },
    maxProgress: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      currentProgress: this.progress,
    };
  },
  computed: {
    barStyle() {
      return {
        width: `${this.currentProgress}%`,
        backgroundColor: this.barColor,
      };
    },
  },
  watch: {
    progress(newVal) {
      this.currentProgress = newVal;
    },
  },
  mounted() {
    if (this.autoIncrease) {
      this.startProgress();
    }
  },
  methods: {
    startProgress() {
      const progressInterval = setInterval(() => {
        if (this.currentProgress < this.maxProgress) {
          this.currentProgress++;
        } else {
          clearInterval(progressInterval);
        }
      }, this.interval);
    },
  },
};
</script>

<style scoped>
.progress-container {
  width: 100%;
  height: 10px;
  background-color: var(--background-color, #f3f3f3);
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.progress-bar {
  height: 100%;
  border-radius: 5px;
  transition: width 0.2s ease-in-out;
}
</style>
