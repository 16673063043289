<template>
  <div>
    <div class="pdf-header px-3 d-flex justify-content-between align-items-center">
      <proofViewPagination :num-pages="fileTotalPage" :cur-page="pageNum" :go-to-next-page="nextPage"
        :go-to-previous-page="prevPage" :isProof="false" />
      <div class="d-flex gap-2">
        <button @click="downloadPdf" class="download-button d-flex align-items-center">
          <span class="download-text me-2">Download</span>
          <img src="/img/download-dark.svg" alt="Download" />
        </button>
        <button @click="openCreateProofModal" class="create-proof-button d-flex align-items-center">
          <span class="create-proof-text text-white me-2">Create New Proof</span>
          <img src="/img/plus-white.svg" alt="Create Proof" />
        </button>
      </div>
    </div>

    <div class="pdf-container">
      <div class="pdf-wrapper">
        <pdf :src="pdfData" :page="pageNum" />
      </div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf';
import proofViewPagination from '../review/proof-view-pagination.vue';
import createPage from '../../pages/CreatePage.vue';

export default {
  name: 'preflightPdfViewer',
  components: {
    pdf,
    proofViewPagination,
    createPage
  },
  props: {
    pdfData: {
      type: Uint8Array,
      required: true,
    },
    fileTotalPage: {
      type: Number,
      required: true,
    },
    pdfFile: {
      type: File,
      required: true,
    },
    preflightId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      pageNum: 1,
    };
  },
  methods: {
    nextPage() {
      if (this.pageNum < this.fileTotalPage) {
        this.pageNum++;
      }
    },
    prevPage() {
      if (this.pageNum > 1) {
        this.pageNum--;
      }
    },
    async downloadPdf() {
      try {
        console.log('Downloading preflight:', this.preflightId);
        const downloadUrl = await window.$A.PreflightService.GetPreflightDownloadUrl(this.preflightId);
        console.log('Download URL:', downloadUrl);
        
        if (downloadUrl && downloadUrl.length > 0) {
          window.open(downloadUrl, '_blank');
        } else {
          console.error('No download URL returned');
          window.$A.AlertUser('No download URL found', 'error');
        }
      } catch (error) {
        console.error('Error downloading PDF:', error);
        window.$A.AlertUser('Error downloading PDF', 'error');
      }
    },
    openCreateProofModal() {
      this.$router.push('/create');
    },
  },
};
</script>

<style scoped>
.pdf-header {
  background-color: #ffff;
  margin-bottom: 40px;
}

.page-info {
  margin: 0 10px;
}

.download-button {
  padding: 9px 15px 9px 17px;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #D1D5DB;
  box-shadow: 0px 1px 2px 0px #080B0D0D;
  background-color: #ffff;
  margin: 10px 0;
  transition: all 0.3s ease;
}

.download-button:hover {
  background-color: #e0e0e0;
}

.download-text {
  color: #374151;
}

.create-proof-button {
  padding: 9px 15px 9px 17px;
  gap: 8px;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px #080B0D0D;
  background-color: #006F41;
  border: none;
  margin: 10px 0;
  transition: all 0.3s ease;
}

.create-proof-button:hover {
  background-color: #017b46;
}

::v-deep .modal-header,
::v-deep .modal-body {
  background-color: #F5FAFA;
}

::v-deep .modal-body {
  max-height: 85vh;
  overflow: auto;
}

::v-deep .modal-body::-webkit-scrollbar {
  display: none;
}

.pdf-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: calc(100vh - 165px);
}

.pdf-wrapper {
  width: 50%;
  height: 100%;
  overflow: auto;
  display: block;
  position: relative;
}

.pdf-wrapper::-webkit-scrollbar {
  display: none;
}
</style>
